import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledTreeCardsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (${BP.tablet}) {
    margin: 0 ${Rem(100)};
  }
`;

export const StyledTreeCardsCta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: ${Rem(100)};
`;
