import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import Intro from "../Intro_v2/Intro_v2";
import { StyledTreeCardsContainer } from "./style";
import Link from "../Link/Link";

const TreeCards = ({
  id,
  title,
  subtitle,
  variant,
  ctaLabel,
}) => {
  const { i18nOasiLink } = useContext(DataContext);

  return (
    <StyledTreeCardsContainer id={id}>
      <Intro
        bigText={title}
        subtitle={subtitle}
      />
      <div>
        {/* TODO */}
      </div>
      {/*<StyledTreeCardsCta>
        <Link>
          to={i18nOasiLink("/trees")}
          <Button
            variant={variant}
          >
            {ctaLabel}
          </Button>
        </Link>
      </StyledTreeCardsCta>*/}
    </StyledTreeCardsContainer>
  );
};

export default TreeCards;
